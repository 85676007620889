/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onInitialClientRender = () => {
  if (typeof window !== "undefined" && window.onInitialClientRender != null) {
    window.onInitialClientRender()
  }
}

//
// HubSpot chat doesn't work well with SPAs so we need to tell it when to
// update/load. It will check the URL to determine how it should render on the
// page gatsby-plugin-hubspot adds the script in its gatsby-ssr.js file using
// the onRenderBody API:
//
// https://github.com/hutsoninc/gatsby-plugin-hubspot/blob/master/src/gatsby-ssr.js
//
// So, perhaps there's a better place to run the following code but this seems
// to do the trick!
//
exports.onRouteUpdate = () => {
  function onConversationsAPIReady() {
    const status = window.HubSpotConversations.widget.status()

    if (status.loaded) {
      window.HubSpotConversations.widget.refresh()
    } else {
      window.HubSpotConversations.widget.load()
    }
  }

  // If HubSpot API methods are already available, use them.
  if (window.HubSpotConversations) {
    onConversationsAPIReady()
  } else {
    // Add onConversationsAPIReady callback to the provided hsConversationsOnReady method
    window.hsConversationsOnReady = [onConversationsAPIReady]
  }
}
